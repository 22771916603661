import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { clsx } from "clsx"
import { useField } from "formik"
import { FC, useMemo, useState } from "react"
import { BiX } from "react-icons/bi"
import { pluralize } from "../../../helpers/strings/pluralize"
import { isOneDimensional } from "../../../helpers/util-functions"
import { TailwindIcon } from "../../../types/tailwind"
import { SelectAll } from "./SelectAll"

export type MultiSelectOption = {
  id: string
  label: string
  value: string
  searchableTextString: string
  template?: (item: MultiSelectOption) => JSX.Element
  disabled?: boolean
}

type Props = {
  containerClassName?: string
  label?: string
  name: string
  Icon?: TailwindIcon
  placeholder?: string
  options: MultiSelectOption[][] | MultiSelectOption[]
  clearable?: boolean
  required?: boolean
  disabled?: boolean
  isSingleSelect?: boolean
  selectedLabel?: string
  withSelectAll?: boolean
  withErrorHandling?: boolean
  onChange?: (selectedValues: string[]) => void
}

export const MuiMultiSelect: FC<Props> = ({
  containerClassName,
  label,
  name,
  Icon,
  placeholder,
  options,
  required,
  disabled = false,
  clearable = false,
  isSingleSelect = false,
  selectedLabel,
  withSelectAll = false,
  withErrorHandling = false,
  onChange = () => {},
}) => {
  const [open, setOpen] = useState(false)
  const [field, meta, helpers] = useField(name)

  const isGrouped = !isOneDimensional(options)
  const flattedOptions = options.flat()
  const optionLabel = selectedLabel || label || "option"
  const value = field.value && Array.isArray(field.value) && isSingleSelect ? field.value[0] : field.value

  const selectedOptions = useMemo(() => {
    return isGrouped
      ? (options as MultiSelectOption[][]).map((option) => option.filter((opt) => field.value.includes(opt.id)))
      : (options as MultiSelectOption[]).filter((option) => field?.value?.includes?.(option.id))
  }, [field.value, isGrouped, options])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleChange = (event: SelectChangeEvent) => {
    const newValue: string | string[] = event.target.value
    const array = Array.isArray(newValue) ? newValue : [newValue]
    helpers.setValue(array)
    onChange?.(array)
  }

  const renderEmptyValue = <Typography className="text-gray-500">{placeholder || `Select ${optionLabel}`}</Typography>

  const renderValue = (selected: string | string[]) => {
    if (!selected || !selected.length) return ""

    if (isSingleSelect) {
      const selectedOption = flattedOptions.find((option) => option.value === selected)
      return selectedOption ? selectedOption.label : open ? renderEmptyValue : ""
    }

    return selected && selected.length
      ? `${selected.length} ${pluralize(optionLabel, selected.length)}`
      : renderEmptyValue
  }

  return (
    <FormControl
      fullWidth
      size="small"
      className={containerClassName}
      required={required}
      error={meta.touched && !!meta.error}
    >
      {label && (
        <InputLabel id={`${name}-label`} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <Select
        aria-label={label}
        aria-labelledby={`${name}-label`}
        className={clsx(Icon && "pl-0")}
        disabled={disabled}
        error={meta.touched && !!meta.error}
        id={name}
        label={required ? `${label} *` : label}
        labelId={`${name}-label`}
        multiple={!isSingleSelect}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        renderValue={renderValue}
        value={value ?? ""}
        startAdornment={
          Icon ? (
            <InputAdornment
              position="start"
              className="flex justify-center items-center rounded-l border-gray-400 border-r bg-gray-50 size-10"
            >
              <Icon className="size-6 text-gray-500" />
            </InputAdornment>
          ) : null
        }
        endAdornment={
          clearable ? (
            <InputAdornment sx={{ display: value ? "" : "none", paddingRight: "1rem" }} position="end">
              <BiX className="size-6" onClick={() => helpers.setValue("")} />
            </InputAdornment>
          ) : null
        }
        MenuProps={{
          hideBackdrop: true,
          onClick: handleClose,
          onClose: handleClose,
          PaperProps: {
            sx: {
              maxHeight: 360,
            },
          },
        }}
      >
        {!isSingleSelect && withSelectAll && (
          <MenuItem className="p-0" onClick={(event) => event.stopPropagation()}>
            <SelectAll name={name} matchedOptions={options} selectedOptions={selectedOptions} />
          </MenuItem>
        )}
        {flattedOptions.length ? (
          flattedOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={option.value}
              onClick={(event) => event.stopPropagation()}
              disabled={option.disabled}
            >
              {!isSingleSelect && (
                <Checkbox checked={!!field.value.find((val: string) => option.value == val)} onChange={handleChange} />
              )}
              {option.template ? option.template(option) : option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            <em>No {pluralize(optionLabel, 0)} available to assign</em>
          </MenuItem>
        )}
      </Select>
      {withErrorHandling && meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
