import { Typography } from "@mui/material"
import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { IconType } from "react-icons"
import {
  BiBadgeCheck,
  BiBandAid,
  BiBox,
  BiBuilding,
  BiBuildings,
  BiBullseye,
  BiCalculator,
  BiCalendar,
  BiCalendarEvent,
  BiCalendarPlus,
  BiCalendarStar,
  BiCalendarWeek,
  BiCalendarX,
  BiCar,
  BiCheckSquare,
  BiCoffee,
  BiCreditCardFront,
  BiDollarCircle,
  BiEnvelope,
  BiFile,
  BiGitCompare,
  BiGitMerge,
  BiHash,
  BiHealth,
  BiHive,
  BiIdCard,
  BiImage,
  BiInfoSquare,
  BiLayer,
  BiListUl,
  BiMailSend,
  BiMap,
  BiMapPin,
  BiMessageSquareDetail,
  BiMobileAlt,
  BiMoveHorizontal,
  BiNote,
  BiNotepad,
  BiPencil,
  BiPhone,
  BiPulse,
  BiPurchaseTag,
  BiRuler,
  BiSearchAlt,
  BiSitemap,
  BiSolidWatch,
  BiTagAlt,
  BiTimeFive,
  BiTrash,
  BiUserCircle,
  BiUserPin,
  BiWrench,
} from "react-icons/bi"
import { FaSignature } from "react-icons/fa"

type IconLabels = keyof typeof icons

const icons = {
  Address: BiMapPin,
  "Asset Name": BiMessageSquareDetail,
  "Asset Number": BiHash,
  Category: BiCar,
  "Company User ID": BiIdCard,
  Contract: BiFile,
  "Contract Name": BiFile,
  "Contract Number": BiHash,
  Coordinates: BiMap,
  Customer: BiBuildings,
  "Predecessor(s)": BiGitCompare,
  "Successor(s)": BiGitCompare,
  Description: BiNotepad,
  Division: BiHive,
  Duration: BiMoveHorizontal,
  Email: BiEnvelope,
  "End Date": BiCalendarWeek,
  "Estimated Hours": BiSolidWatch,
  ID: BiHash,
  "Job Title": BiTagAlt,
  "Location Notes": BiNote,
  "Man-Hours": BiSolidWatch,
  Manufacturer: BiBuilding,
  Model: BiInfoSquare,
  Name: BiUserPin,
  "Non-Workdays": BiCalendarX,
  "Owned / Rented": BiPurchaseTag,
  "Parent Task": BiGitMerge,
  Pay: BiDollarCircle,
  Phone: BiMobileAlt,
  "Photo Required": BiImage,
  "Project Code": BiHash,
  "Project Name": BiLayer,
  "Project Radius": BiBullseye,
  "Purchase Date": BiCalendarEvent,
  "Purchase Price": BiDollarCircle,
  "Reports To": BiSitemap,
  Role: BiBadgeCheck,
  "Schedule Frequency": BiCalendarWeek,
  "Start & End Date": BiCalendarPlus,
  "Rental Duration": BiCalendarPlus,
  "Start Date": BiCalendarWeek,
  "Summary Task Name": BiListUl,
  "Task Name": BiListUl,
  Template: BiSearchAlt,
  Unit: BiRuler,
  "Vin / Serial #": BiCreditCardFront,
  "Work Days": BiCalendar,
  "Workday Breaks": BiCoffee,
  "Workday Hours": BiTimeFive,
  "Billing Classification": BiCalculator,
  "Workers Comp Code": BiBandAid,
  "Working Days": BiCalendarWeek,
  Year: BiCalendarStar,
  "Archive Project": BiBox,
  "Complete Task": BiCheckSquare,
  "Delete Task": BiTrash,
  "Rates (Day, Week, 4-Week)": BiDollarCircle,
  "Vendor Name": BiBuildings,
  "Vendor Email": BiEnvelope,
  "Vendor Phone": BiPhone,
  "Injury report note": BiNote,
  "Photos and signatures": BiImage,
  "Time Entry Photos": BiImage,
  "Injury report & signature": FaSignature,
  "Email invitations": BiMailSend,
  "Injury report": BiHealth,
  "Asset inspection failed": BiWrench,
  "Company Name": BiBuildings,
  "Default Time Zone": BiTimeFive,
  Status: BiPulse,
  "Unit Reporting": BiPencil,
  "Summary Task Duration": BiMoveHorizontal,
  "Team Member Time Entries": BiUserCircle,
}

type LabeledSectionProps = {
  children: ReactNode // Might just be a string
  className?: string
  label: IconLabels
  hideLabel?: boolean
  note?: boolean
}

export const LabeledSection: FC<LabeledSectionProps> = ({ children, className, hideLabel, label, note }) => {
  const icon = icons[label]
  const inlineText = label === "Description" || label === "Location Notes"

  return (
    <CustomLabeledSection {...{ label, icon, hideLabel, inlineText, className, note }}>{children}</CustomLabeledSection>
  )
}

type Props = {
  children?: ReactNode
  className?: string
  circularIcon?: boolean
  inlineText?: boolean
  label: string | number
  note?: boolean
  icon?: IconType
  imageUrl?: string | null
  hideLabel?: boolean
  reportingUnitLabel?: boolean
}

export const CustomLabeledSection: FC<Props> = ({
  icon,
  label,
  circularIcon,
  className,
  children,
  imageUrl = "",
  note,
  inlineText,
  hideLabel = false,
  reportingUnitLabel,
}) => {
  const IconComponent = icon
  const labelClassName = "text-gray-800 font-medium text-base mr-3 whitespace-nowrap pt-2"
  const childrenClassName = "text-gray-500 font-normal text-base mt-2 flex flex-row items-center gap-0"

  return (
    <div className={clsx(!!(icon || imageUrl) && "flex gap-3", className)}>
      {!imageUrl && icon && (
        <div className={clsx("h-10 min-w-[40px] flex bg-gray-100", circularIcon ? "rounded-full" : "rounded-lg")}>
          {IconComponent && <IconComponent className="h-5 w-[20px] m-auto" />}
        </div>
      )}
      {imageUrl && <img alt={`${label}`} src={imageUrl} className="size-10 rounded-lg" />}
      <div className={clsx("flex items-start", note && "flex-col")}>
        {inlineText ? (
          <div className={labelClassName}>
            Description <span className={childrenClassName}>{children}</span>
          </div>
        ) : (
          <>
            {!hideLabel && <p className={clsx(labelClassName, note && "pb-2")}>{label}</p>}
            {!hideLabel && reportingUnitLabel && <Typography></Typography>}
            <div className={childrenClassName}>{children}</div>
          </>
        )}
      </div>
    </div>
  )
}
