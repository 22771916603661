import { Checkbox, Chip, MenuItem, Autocomplete as MuiAutoComplete, TextField as MuiTextField } from "@mui/material"
import { useField } from "formik"
import debounce from "lodash.debounce"
import { HTMLAttributes, useCallback, useEffect, useState } from "react"
import { Division } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"
import { AllOrganizationDivisionsQuery } from "../../graphql/generated/gql/graphql"
import { DivisionBadge } from "../DivisionBadge"
import { OrganizationImage } from "../OrganizationImage"

type Option = {
  value: string
  label: string
  imageUrl?: string
}

export type DivisionSelectionExpectation = PickPlus<
  AllOrganizationDivisionsQuery["myOrganization"]["divisions"][0],
  "id" | "name" | "imageUrl"
> & {
  isCurrentAssignment?: boolean
  divisionAccess?: boolean
}

type Props = {
  divisions: DivisionSelectionExpectation[]
  name: string
  label: string
  className?: string
  disabled?: boolean
  multiple?: boolean
  preselected?: Option[]
  required?: boolean
  onChangeNotify?: (val: string[]) => void
}

const sortOptionsByDivisionName = (a: Option, b: Option) => {
  const aKey = (a.label || "").toLowerCase()
  const bKey = (b.label || "").toLowerCase()

  // Sort by project name, ascending
  if (aKey > bKey) return 1
  if (aKey < bKey) return -1
  return 0
}

type RenderListProps = HTMLAttributes<HTMLLIElement> & {
  key: string
}

export const DivisionSelect = ({
  divisions,
  name,
  label,
  className,
  disabled,
  multiple = false,
  preselected = [],
  required = false,
  onChangeNotify,
}: Props) => {
  const [searchText, setSearchText] = useState("")
  const [options, setOptions] = useState<Option[]>(
    () =>
      divisions.map((division) => ({
        value: division.id,
        label: division.name,
        imageUrl: division.imageUrl,
      })) as Option[]
  )

  const [selected, setSelected] = useState<Option[]>(() =>
    options.filter((division) => preselected.some((pre) => pre.value === division.value))
  )
  const [_, { error, touched }, { setValue }] = useField(name)
  const displayError = touched && error && !disabled

  useEffect(() => {
    if (multiple) setValue(selected.map((item) => item.value))
    else setValue(selected?.[0]?.value ?? "")
  }, [selected, setValue, multiple])

  useEffect(() => {
    if (searchText) {
      const filteredOptions: Option[] = divisions
        .filter((division) => {
          return division?.name?.toLowerCase().includes(searchText.toLowerCase())
        })
        .map((division) => ({
          value: division.id,
          label: division.name,
          imageUrl: division.imageUrl,
        })) as Option[]
      setOptions([...preselected, ...filteredOptions])
    } else if (options.length !== divisions.length) {
      setOptions(
        divisions.map((division) => ({
          value: division.id,
          label: division.name,
          imageUrl: division.imageUrl,
        })) as Option[]
      )
    }
  }, [divisions, options, searchText, preselected])

  const setSearchTextProperly = (newSearchTxt: string) => {
    setSearchText(newSearchTxt)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(debounce(setSearchTextProperly, 500), [])

  // Implementation Docs for "search as you type"
  // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
  return (
    <MuiAutoComplete
      multiple
      disableCloseOnSelect={multiple}
      className={className}
      sx={{
        dropdown: {
          "&.MuiAutocomplete-root": {
            width: "100%",
          },
        },
        width: "100%",
      }}
      options={options}
      value={selected}
      renderOption={(optionProps, option, { selected: selectedVal }) => {
        const { key, ...props } = optionProps as RenderListProps
        return (
          <MenuItem key={key} {...props}>
            {multiple && <Checkbox checked={selectedVal} />}
            <DivisionBadge
              division={divisions.find((division) => division.id === option.value) as unknown as Division}
              className="ml-2"
            />
          </MenuItem>
        )
      }}
      renderTags={(val) => {
        return (
          <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
            {val.map((division) => {
              if (!division) return null
              return (
                <Chip
                  sx={{ margin: "3px" }}
                  key={division.value}
                  label={division.label}
                  variant="outlined"
                  onDelete={
                    multiple
                      ? () => {
                          setSelected((selectedValues) =>
                            selectedValues.filter((option: Option) => option.value !== division.value)
                          )
                        }
                      : undefined
                  }
                  avatar={<OrganizationImage organization={division} className="size-6 rounded-xl" />}
                />
              )
            })}
          </div>
        )
      }}
      filterOptions={(x) => x.sort(sortOptionsByDivisionName)}
      onChange={(_props, values) => {
        let selectedOption: Option | null = null
        if (!Array.isArray(values)) {
          setSelected([])
        } else if (multiple) {
          setSelected(values)
        } else {
          const singleSelect = []
          selectedOption = values[values.length - 1]
          if (selectedOption) singleSelect.push(selectedOption)
          setSelected(singleSelect)
        }

        onChangeNotify?.(selectedOption ? [selectedOption.value] : values.map((item) => item.value))
      }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          className={className}
          size="small"
          label={required ? `${label} *` : label}
          onChange={(e) => handleDebounce(e.target.value)}
          helperText={displayError ? error : <>&nbsp;</>}
          error={!!displayError}
          inputProps={{
            ...(params.inputProps || {}),
            style: { boxShadow: "none", minHeight: "33px" },
          }}
          FormHelperTextProps={{ className: "my-0.5" }}
        />
      )}
      isOptionEqualToValue={(option, value) => value?.value === option?.value}
    />
  )
}
