import { Box, Chip, Typography } from "@mui/material"
import { clsx } from "clsx"
import { format } from "date-fns"
import { FC, useContext } from "react"
import { BiSearchAlt, BiSortAlt2 } from "react-icons/bi"
import { useAssetDetailsPageQuery } from "../../../graphql/generated/client-types-and-hooks"
import { getDateFromDateString } from "../../../helpers/dateAndTime/getDateFromDateString"
import { formatMoney } from "../../../helpers/formatters/formatMoney"
import { colors } from "../../../helpers/styles/colors"
import { useHandleError } from "../../../hooks/useHandleError"
import { CustomLabeledSection, LabeledSection } from "../../LabeledSection"
import { PageTitle } from "../../PageTitle"
import { H5 } from "../../deprecated"
import { AssetSkeleton } from "./Asset.skeleton"
import { InventoryIntervalLookup } from "./CreateOrEditAssetForm/InventoryIntervalOptions"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { FaEllipsisH } from "react-icons/fa"
import { QuickMenuMui } from "../../QuickMenuMui"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { TransferAssetToDivisionModal } from "../../Modals/components/TransferAssetModal/TransferAssetToDivisionModal"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { useRouter } from "next/router"

type Props = {
  assetId: string
}

type ValueOrNoneLabelProps = {
  value?: string | number | null | undefined
  className?: string
}

export const ValueOrNoneLabel: FC<ValueOrNoneLabelProps> = ({ value, className }) => {
  return value ? (
    <span className={clsx(className, "whitespace-normal break-all")}>{value}</span>
  ) : (
    <span className="text-gray-500">None</span>
  )
}

export const BasicInfo: FC<Props> = ({ assetId }) => {
  const router = useRouter()
  const { pathname } = router
  const { clearAll } = useContext(DrawerContext)
  const [{ data, fetching, error }] = useAssetDetailsPageQuery({
    variables: { id: assetId },
  })
  useHandleError(error, "An error occurred while fetching asset details.")
  const assetDivisionTransferModalProps = useModalProps("Switch Division")

  const isGroupParent = data?.asset?.isAssetGroup
  const inspectionAssignments = data?.asset?.inspectionTemplatesAssignments || []

  const handleDivisionTransferSuccess = () => {
    const isAssetPage = pathname === "/assets/[_id]/details"

    if (isAssetPage) {
      router.push("/assets")
    } else {
      clearAll()
      assetDivisionTransferModalProps.handleCloseModal()
    }
  }

  if (fetching || !data?.asset) {
    return <AssetSkeleton />
  }

  return (
    <div className="flex flex-col gap-9">
      <PageTitle title={`${data.asset.name} details`} />
      <div>
        <H5 className="my-0">Basic Info</H5>
        <hr className="mt-2 mb-4 md:mb-6" />
        <div className="grid gap-y-1">
          <div className="md:grid md:grid-cols-2 md:gap-4 grid gap-y-4">
            <LabeledSection label="Asset Name">
              <ValueOrNoneLabel value={data.asset.name} />
            </LabeledSection>
            {!isGroupParent && (
              <>
                <LabeledSection label="Year">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.year} />
                </LabeledSection>
                <LabeledSection label="Asset Number">
                  <ValueOrNoneLabel value={data.asset.companyAssetNumber} />
                </LabeledSection>
                <DevelopmentFeatureFlag name="Divisions">
                  <LabeledSection label="Division" className="md:col-span-1">
                    <ValueOrNoneLabel value={data.asset.division?.name} />
                    <QuickMenuMui
                      className="size-1 px-1 py-3"
                      items={[
                        [
                          {
                            requiredPermission: "divisions:update",
                            value: "Switch Division",
                            Icon: BiSortAlt2,
                            onClick: () => assetDivisionTransferModalProps.handleOpenModal(),
                          },
                        ],
                      ]}
                    >
                      <FaEllipsisH className="text-gray-500" />
                    </QuickMenuMui>
                  </LabeledSection>
                </DevelopmentFeatureFlag>
                <LabeledSection label="Manufacturer">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.make} />
                </LabeledSection>
                <LabeledSection label="Vin / Serial #">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.id} />
                </LabeledSection>
                <LabeledSection label="Model">
                  <ValueOrNoneLabel value={data.asset.manufacturer?.model} />
                </LabeledSection>
                <LabeledSection label="Category">
                  <ValueOrNoneLabel
                    value={
                      data.asset.category === "SmallEquipment"
                        ? "Small Equipment"
                        : data.asset.category === "HeavyEquipment"
                          ? "Heavy Equipment"
                          : data.asset.category
                    }
                  />
                </LabeledSection>
                <DevelopmentFeatureFlag name="Billing Classifications">
                  <LabeledSection label="Billing Classification">
                    <ValueOrNoneLabel value={data.asset.billingClassification?.name} />
                  </LabeledSection>
                </DevelopmentFeatureFlag>
              </>
            )}
          </div>

          {isGroupParent || (
            <>
              <H5 className="mt-8">Owned / Rented</H5>
              <div className="grid gap-y-4">
                <LabeledSection label="Owned / Rented">
                  {data.asset.ownershipType === "OWN" ? "Owned" : "Rented"}
                </LabeledSection>
                {data.asset.ownershipType === "OWN" && (
                  <>
                    <LabeledSection label="Purchase Price">
                      <ValueOrNoneLabel value={formatMoney(data.asset.purchaseDetails?.price)} />
                    </LabeledSection>
                    <LabeledSection label="Purchase Date">
                      <ValueOrNoneLabel value={data.asset.purchaseDetails?.date} />
                    </LabeledSection>
                  </>
                )}
                {data.asset.ownershipType === "RENT" && (
                  <>
                    <LabeledSection label="Rental Duration">
                      <ValueOrNoneLabel
                        value={
                          data.asset.rentalAgreement?.startOn
                            ? format(getDateFromDateString(data.asset.rentalAgreement.startOn), "MM/dd/yyyy")
                            : undefined
                        }
                      />
                      &nbsp;-&nbsp;
                      <ValueOrNoneLabel
                        value={
                          data.asset.rentalAgreement?.endOn
                            ? format(getDateFromDateString(data.asset.rentalAgreement.endOn), "MM/dd/yyyy")
                            : undefined
                        }
                      />
                    </LabeledSection>
                    <LabeledSection label="Rates (Day, Week, 4-Week)">
                      <ValueOrNoneLabel value={formatMoney(data.asset.rentalAgreement?.rate?.daily)} />
                      &nbsp;&middot;&nbsp;
                      <ValueOrNoneLabel value={formatMoney(data.asset.rentalAgreement?.rate?.weekly)} />
                      &nbsp;&middot;&nbsp;
                      <ValueOrNoneLabel value={formatMoney(data.asset.rentalAgreement?.rate?.monthly)} />
                    </LabeledSection>
                    <LabeledSection label="Vendor Name">
                      <ValueOrNoneLabel value={data.asset.vendorContact?.name} />
                    </LabeledSection>
                    <LabeledSection label="Vendor Email">
                      <ValueOrNoneLabel value={data.asset.vendorContact?.email} />
                    </LabeledSection>
                    <LabeledSection label="Vendor Phone">
                      <ValueOrNoneLabel value={data.asset.vendorContact?.phone} />
                    </LabeledSection>
                  </>
                )}
              </div>
            </>
          )}
          <H5 className="mt-8">Inventory Settings</H5>
          <div className="grid gap-y-4">
            <LabeledSection label="Schedule Frequency">
              <ValueOrNoneLabel
                value={
                  data.asset.inventoryRequirements?.intervalInSeconds &&
                  InventoryIntervalLookup[data.asset.inventoryRequirements?.intervalInSeconds]
                }
              />
            </LabeledSection>
            <LabeledSection label="Photo Required">
              <ValueOrNoneLabel value={data.asset.inventoryRequirements?.photoRequired ? "Yes" : "No"} />
            </LabeledSection>
          </div>
          <>
            <Typography variant="h5" marginTop={4}>
              Inspection Checklist
            </Typography>

            {inspectionAssignments.length === 0 && (
              <Box>
                <Typography textAlign="center" color={colors.gray[500]}>
                  No inspection checklists
                </Typography>
              </Box>
            )}

            <Box display="grid" gap={2}>
              {inspectionAssignments.map((assignment) => {
                return (
                  <div key={assignment.id}>
                    <CustomLabeledSection label={assignment?.inspectionTemplate?.name} icon={BiSearchAlt}>
                      <Box display="flex" gap={1} alignItems="center" paddingTop={0.5} marginTop="-0.5rem">
                        <Chip
                          label={
                            <Box display="flex" gap={1}>
                              <Typography>Schedule Frequency </Typography>
                              <Typography color={colors.gray[500]}>
                                {assignment?.intervalInSeconds
                                  ? InventoryIntervalLookup[assignment?.intervalInSeconds]
                                  : "None"}
                              </Typography>
                            </Box>
                          }
                        />

                        <Chip
                          label={
                            <Box display="flex" gap={1}>
                              <Typography>Start Date</Typography>
                              <Typography color={colors.gray[500]}>
                                {assignment?.startDate ? format(assignment.startDate, "E, MMM do yyyy") : "None"}
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                    </CustomLabeledSection>
                  </div>
                )
              })}
            </Box>
          </>
        </div>
      </div>
      {assetDivisionTransferModalProps.isOpen && (
        <TransferAssetToDivisionModal
          modalProps={assetDivisionTransferModalProps}
          selectedAssetId={assetId}
          onSuccess={handleDivisionTransferSuccess}
        />
      )}
    </div>
  )
}
